@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;900&display=swap');

input {
  caret-color: blue;
}

.desmi-form {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 2%;
  border-radius: 20px;
  box-sizing: border-box;
  background: #001F3D;
}

.desmi-form::-webkit-scrollbar {
  width: 8px;
}

.desmi-form::-webkit-scrollbar-track {
  background: #001F3D;
}

.desmi-form::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.desmi-form::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.desmineralizer {
  height: 100px;
  width: 100px;
  box-sizing: border-box;
}

.startext {
  margin-bottom: 2%;
  font-weight: 900;
  font-size: 1.8rem;
  color: #1DA1F2;
  letter-spacing: 1px;
}

.griddesmi {
  width: 50%;
}

label, input, button {
  display: block;
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
}

select {
  background: #ecf0f3;
  margin-bottom: 3%;
  padding: 1.7%;
  width: 95%;
  font-size: 14px;
  border-radius: 50px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
}

input[type="checkbox"] {
  width: calc(100% - 20px);
  padding: 8px;
  border: 1px solid #464646;
  border-radius: 4px;
  box-sizing: border-box;
}
.input-desmi {
  position: relative;
  caret-color: blue;
  width: 100%;
  font-size: 14px;
  border-radius: 50px;
}

.desmi-input {
  display: flex;
  align-items: center;
}

.desmi {
  position: absolute;
  right: 7%;
  top: 53%;
  transform: translateY(-70%);
  color: #666;
}

.all-desmi {
  width: 50%;
  font-size: 14px;
}

.input-desmi {
  position: relative;
  caret-color: blue;
  width: 100%;
  font-size: 14px;
  border-radius: 50px;
}

.all-input {
  position: relative;
  display: flex;
  align-items: center;
}

.all {
  position: absolute;
  right: 8%;
  top: 45%;
  transform: translateY(-70%);
  color: #666;
}

.paragrafo {
  font-size: 13px;
  transition: all 300ms ease-out;
}

.paragrafo:hover {
  color: #32cd32;
  font-size: 14px;
}

p {
  margin-top: 0;
}