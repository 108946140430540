@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;900&display=swap');

input {
  caret-color: blue;
}

.form-rel {
  position: relative;
  width: 70%;
  height: 100%;
  margin-left: 15%;
  padding: 2%;
  border-radius: 20px;
  box-sizing: border-box;
  background: #001F3D;
}

.order {
  height: 100px;
  width: 100px;
  box-sizing: border-box;
}

.ordertext {
  margin-bottom: 2%;
  font-weight: 900;
  font-size: 1.8rem;
  color: #1DA1F2;
  letter-spacing: 1px;
}

.order-column {
  columns: 1;
}

label, input, button {
  display: block;
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
}

select {
  background: #ecf0f3;
  margin-bottom: 3%;
  padding: 1.7%;
  width: 95%;
  font-size: 14px;
  border-radius: 50px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
}

.data {
  margin-bottom: 3%;
  padding: 1.7%;
  width: 95%;
  font-size: 14px;
  border-radius: 50px;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .form {
    padding: 3%;
  }

  .ordertext {
    font-size: 1.5rem;
  }

  select, .data {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .form {
    padding: 4%;
  }

  .ordertext {
    font-size: 1.3rem;
  }

  select, .data {
    width: 100%;
    padding: 1.5%;
  }
}

@media (max-width: 600px) {
  .form {
    padding: 5%;
  }

  .ordertext {
    font-size: 1.2rem;
  }

  select, .data {
    width: 100%;
    padding: 1.2%;
  }
}
