.section-lab { 
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 200px);
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.section-lab h1 {
  font-size: 2.5rem;
  text-align: center;
}

.lab-icones {
  height: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.wraplab {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 50px;
  padding: 0 20px;
  box-sizing: border-box;
}

.iconlab {
  text-decoration: none;
  width: 10.625rem;
  height: 10.625rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 10px;
  overflow: hidden;
}

.iconlab > .lab-icones {
  font-size: 1.5rem;
  transition: all 0.2s;
}

.iconlab img {
  transition: all 0.3s ease;
}

.iconlab:hover img {
  transform: scale(1.4);
  fill: #005f8f;
  opacity: 0.1;
}

.iconlab::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 100%;
  background: linear-gradient(220.55deg, #005f8f 0%, #007f8f 100%);
  scale: 1;
  z-index: -1;
  transition: all 300ms ease-out;
}

.iconlab:hover::before {
  scale: 0;
}

.iconlab .hover-text {
  position: absolute;
  transform: translateY(-50%);
  margin-top: 25px;
  color: white;
  font-size: 1.25rem;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1;
}

.iconlab:hover .hover-text {
  opacity: 1;
}

@media (max-width: 1024px) {
  .section-lab {
    height: auto;
    padding: 20px;
  }

  .section-lab h1 {
    font-size: 2rem;
  }

  .iconlab {
    width: 8.75rem;
    height: 8.75rem;
  }

  .iconlab .hover-text {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .section-lab h1 {
    font-size: 1.5rem;
  }

  .iconlab {
    width: 7.5rem;
    height: 7.5rem;
  }

  .iconlab .hover-text {
    font-size: 0.875rem;
  }
}

@media (max-width: 600px) {
  header {
    padding: 10px 20px !important;
  }

  .section-lab { 
    padding: 0 20px 20px 20px !important;
    width: calc(100% - 40px) !important;
    height: auto;
  }

  .section-lab h1 {
    font-size: 1.25rem;
  }

  .iconlab {
    width: 6.25rem;
    height: 6.25rem;
  }

  .iconlab .hover-text {
    font-size: 0.75rem;
  }
}
