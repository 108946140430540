.sidebar {
  width: 80px;
  height: 100%;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.sidebar-items {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 8px;
  margin-top: 8%;
  border-radius: 15px;
  cursor: pointer;
  color: ghostwhite;
  background-color: #005f8f;
  transition: transform 0.3s ease-in-out;
}

.save {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 80px;
  margin-top: 375%;
  border-radius: 8px;
  cursor: pointer;
  color: ghostwhite;
  background-color: #008000;
  transition: transform 0.3s ease-in-out;
}

.item:hover {
  background-color: #007f8f;
  transform: scale(1.2);
}

.save:hover {
  background-color: #32cd32;
  transform: scale(1.2);
}

.item img {
  width: 24px;
  height: 24px;
  margin-bottom: 6px;
}

.save img {
  width: 24px;
  height: 24px;
  margin-bottom: 6px;
}

.item span {
  font-size: 11px;
}

.save span {
  font-size: 11px;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .save {
    margin-top: 200%;
    padding: 16px 60px;
  }

  .item {
    padding: 12px 6px;
    margin-top: 6%;
  }

  .item img,
  .save img {
    width: 20px;
    height: 20px;
  }

  .item span,
  .save span {
    font-size: 10px;
  }
}

@media (max-width: 768px) {
  .sidebar {
    width: 60px;
  }

  .save {
    margin-top: 150%;
    padding: 16px 50px;
  }

  .item {
    padding: 10px 4px;
    margin-top: 4%;
  }

  .item img,
  .save img {
    width: 18px;
    height: 18px;
  }

  .item span,
  .save span {
    font-size: 9px;
  }
}

@media (max-width: 600px) {
  .sidebar {
    width: 50px;
  }

  .save {
    margin-top: 100%;
    padding: 16px 40px;
  }

  .item {
    padding: 8px 2px;
    margin-top: 2%;
  }

  .item img,
  .save img {
    width: 16px;
    height: 16px;
  }

  .item span,
  .save span {
    font-size: 8px;
  }
}
