@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;900&display=swap');

input {
  caret-color: blue;
}

.tec-cont {
  position: relative;
  width: 70%;
  margin-left: 15%;
  border-radius: 20px;
  padding: 2%;
  box-sizing: border-box;
  background: #001F3D;
}

.form-tec {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 2%;
  border-radius: 20px;
  box-sizing: border-box;
  background: #001F3D;
}

.start {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  box-sizing: border-box;
}

.startext {
  margin-bottom: 2%;
  font-weight: 900;
  font-size: 1.8rem;
  color: #1DA1F2;
  letter-spacing: 1px;
}

.tecnicos {
  text-align: left;
  columns: 2;
}

.forms-tec {
  text-align: left;
  columns: 3;
}

label, input, button {
  display: block;
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
}

select {
  background: #ecf0f3;
  margin-bottom: 3%;
  padding: 1.7%;
  width: 95%;
  font-size: 14px;
  border-radius: 50px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
}
