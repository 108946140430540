@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;900&display=swap');

input {
  caret-color: blue;
}

.form {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 2%;
  border-radius: 20px;
  box-sizing: border-box;
  background: #001F3D;
}

.popup {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4caf50; /* Cor de fundo verde para sucesso */
  color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
  z-index: 1000;
}


.info {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  box-sizing: border-box;
}

.infotext {
  margin-bottom: 2%;
  font-weight: 900;
  font-size: 1.8rem;
  color: #1DA1F2;
  letter-spacing: 1px;
}

.inputs {
  text-align: left;
  columns: 2;
}

label, input, button {
  display: block;
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
}

.mask {
  display: block;
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
}

select {
  background: #ecf0f3;
  margin-bottom: 3%;
  padding: 1.7%;
  width: 95%;
  font-size: 14px;
  border-radius: 50px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
}

.data {
  margin-bottom: 3%;
  padding: 1.7%;
  width: 95%;
  font-size: 14px;
  border-radius: 50px;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .form {
    padding: 3%;
  }

  .infotext {
    font-size: 1.5rem;
  }

  .inputs {
    columns: 1;
  }

  select, .data {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .form {
    padding: 4%;
  }

  .infotext {
    font-size: 1.3rem;
  }

  select, .data {
    width: 100%;
    padding: 1.5%;
  }
}

@media (max-width: 600px) {
  .form {
    padding: 5%;
  }

  .infotext {
    font-size: 1.2rem;
  }

  .inputs {
    columns: 1;
  }

  select, .data {
    width: 100%;
    padding: 1.2%;
  }
}
