@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;900&display=swap");

input {
  caret-color: blue;
}

.form {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 2%;
  border-radius: 20px;
  box-sizing: border-box;
  background: #001f3d;
}

.treino {
  height: 100px;
  width: 100px;
  box-sizing: border-box;
  display: block;
  margin: 0 auto;
}

.startext {
  margin-bottom: 2%;
  font-weight: 900;
  font-size: 1.8rem;
  color: #1da1f2;
  letter-spacing: 1px;
  text-align: center; /* Alinha o texto no centro */
}

.certif {
  text-align: left;
  columns: 2;
  gap: 1rem; /* Adiciona espaçamento entre colunas */
}

label,
button {
  display: block;
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
}

.certifinput, .data {
  display: block;
  padding: 0.5rem; /* Adiciona algum espaçamento interno */
  border: none;
  outline: none;
  box-sizing: border-box;
  width: 100%; /* Garante que o input ocupe toda a largura disponível */
}

select {
  background: #ecf0f3;
  margin-bottom: 3%;
  padding: 1.7%;
  width: 95%;
  font-size: 14px;
  border-radius: 50px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
}

.sigCanvas {
  border: 1px solid #fff;
  background-color: #cbced1;
  border-radius: 25px;
  touch-action: none; /* Evita problemas de zoom e rolagem em dispositivos móveis */
  display: block; /* Garante que o canvas se comporte corretamente */
  margin: 0 auto; /* Centraliza o canvas */
  width: 100%; /* Ajusta o tamanho do canvas para ocupar a largura completa do contêiner */
  height: 35vh; /* Mantém a proporção correta */
}

.clear {
  margin-top: 2%;
  width: 100%; /* Ocupa a largura total disponível */
  max-width: 200px; /* Limita a largura máxima do botão */
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  color: ghostwhite;
  background-color: #008000;
  text-align: center; /* Alinha o texto no centro */
  display: block; /* Garante que o botão se comporte corretamente */
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .startext {
    font-size: 1.6rem;
  }
  
  .certif {
    columns: 1;
  }
}

@media (max-width: 768px) {
  .form {
    padding: 4%;
  }

  .startext {
    font-size: 1.4rem;
  }
  
  .certif {
    columns: 1;
  }

  .sigCanvas {
    height: 30vh;
  }
}

@media (max-width: 600px) {
  .form {
    padding: 6%;
  }

  .startext {
    font-size: 1.2rem;
  }
  
  .certif {
    columns: 1;
  }

  .sigCanvas {
    height: 25vh;
  }
}
