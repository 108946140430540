.osmose-form {
  max-height: 80vh;
  overflow-y: auto;
  border-radius: 20px;
  box-sizing: border-box;
  background: #001F3D;
  padding: 2%;
}

.osmose-form::-webkit-scrollbar {
  width: 8px;
}

.osmose-form::-webkit-scrollbar-track {
  background: #001F3D;
}

.osmose-form::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.osmose-form::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.equiposm {
  width: 97.5%;
  font-size: 13px;
}

.coluna {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.coluna > div {
  margin-bottom: 10px;
}

.startext {
  margin-top: 20px;
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input::placeholder {
  text-align: right;
}

.osmosis {
  height: 100px;
  width: 100px;
  box-sizing: border-box;
}
.osmosisinput {
  display: block;
  width: 97.5%;
  height: 4.25vh;
  padding: 1px;
  border: none;
  outline: none;
  box-sizing: border-box;
}
