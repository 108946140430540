@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;900&display=swap');

input {
  caret-color: blue;
}

.form {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 2%;
  border-radius: 20px;
  box-sizing: border-box;
  background: #001F3D;
}

.uv {
  height: 100px;
  width: 100px;
  box-sizing: border-box;
}

.uvtext {
  margin-bottom: 2%;
  font-weight: 900;
  font-size: 1.8rem;
  color: #1DA1F2;
  letter-spacing: 1px;
}

.inputs {
  text-align: left;
  columns: 2;
}

label, input, button {
  display: block;
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
}

select {
  background: #ecf0f3;
  margin-bottom: 3%;
  padding: 1.7%;
  width: 95%;
  font-size: 14px;
  border-radius: 50px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
}
