.section-text { 
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 200px);
  justify-content: center;
  align-items: center;
}  

.section-text h1 {
  font-size: 40px;
  margin-bottom: 100px;
}

.img {
  height: 100px;
  display: flex;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 150px;
}

.icon {
  text-decoration: none;
  width: 10.625rem;
  height: 10.625rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 75px;   
}

.icon > img {
  font-size: 1.5rem;
  transition: all 0.2s;
}

.icon:hover img {
  scale: 1.4;
  filter: invert(0%);
}

.icon::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 100%;
  background: linear-gradient(220.55deg, #005f8f 0%, #007f8f 100%);
  scale: 1;
  z-index: -1;
  transition: all 300ms ease-out;
}

.icon:hover::before {
  scale: 0;
}

@media (max-width: 1024px) {
  .section-text h1 {
      font-size: 35px;
      margin-bottom: 75px;
  }

  .icon {
      width: 8.625rem;
      height: 8.625rem;
      margin: 0 50px;
  }

  .icon > img {
      font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .section-text h1 {
      font-size: 30px;
      margin-bottom: 50px;
  }

  .icon {
      width: 7.625rem;
      height: 7.625rem;
      margin: 0 40px;
  }

  .icon > img {
      font-size: 1rem;
  }

  .wrapper {
      flex-direction: column;
      gap: 1rem;
      margin-top: 100px;
  }
}

@media (max-width: 600px) {
  header {
      padding: 10px 20px !important;
  }

  .section-text { 
      padding: 0 20px 20px 20px !important;
      width: calc(100% - 40px) !important;
      height: auto;
  }

  .section-text h1 {
      font-size: 25px;
      margin-bottom: 25px;
  }

  .icon {
      width: 6.625rem;
      height: 6.625rem;
      margin: 0 20px;
  }

  .icon > img {
      font-size: 0.8rem;
  }

  .wrapper {
      flex-direction: column;
      gap: 0.5rem;
      margin-top: 50px;
  }
}
