@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;900&display=swap");

input {
  caret-color: blue;
}

.form {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 2%;
  border-radius: 20px;
  box-sizing: border-box;
  background: #001f3d;
}

.treino {
  height: 100px;
  width: 100px;
  box-sizing: border-box;
}

.startext {
  margin-bottom: 2%;
  font-weight: 900;
  font-size: 1.8rem;
  color: #1da1f2;
  letter-spacing: 1px;
}

.certif {
  text-align: left;
  columns: 2;
}

label,
button {
  display: block;
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
}

.certifinput, .data {
  display: block;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
}

select {
  background: #ecf0f3;
  margin-bottom: 3%;
  padding: 1.7%;
  width: 95%;
  font-size: 14px;
  border-radius: 50px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
}

.sigCanvas {
  border: 1px solid #fff;
  background-color: #cbced1;
  border-radius: 25px;
  touch-action: none; /* Evita problemas de zoom e rolagem em dispositivos móveis */
  display: block; /* Garante que o canvas se comporte corretamente */
  margin: 0 auto; /* Centraliza o canvas, se necessário */
  width: 100%; /* Ajusta o tamanho do canvas para ocupar a largura completa do contêiner */
  height: 35vh; /* Mantém a proporção correta */
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .startext {
    font-size: 1.6rem;
  }
  
  .certif {
    columns: 1;
  }
}

@media (max-width: 768px) {
  .form {
    padding: 4%;
  }

  .startext {
    font-size: 1.4rem;
  }
  
  .certif {
    columns: 1;
  }

  .sigCanvas {
    height: 30vh;
  }
}

@media (max-width: 600px) {
  .form {
    padding: 6%;
  }

  .startext {
    font-size: 1.2rem;
  }
  
  .certif {
    columns: 1;
  }

  .sigCanvas {
    height: 25vh;
  }
}
