@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;900&display=swap');

.catform {
    max-height: 80vh;
    overflow-y: auto;
    border-radius: 20px;
    box-sizing: border-box;
    background: #001F3D;
    padding: 2%;
}

.catform::-webkit-scrollbar {
    width: 8px;
  }
  
  .catform::-webkit-scrollbar-track {
    background: #001F3D;
  }
  
  .catform::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  
  .catform::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

.cattext {
  margin-bottom: 2%;
  font-weight: 900;
  font-size: 1.8rem;
  color: #1DA1F2;
  letter-spacing: 1px;
}

.filter-section {
  margin-bottom: 2%;
}

input {
  background: #ecf0f3;
  margin-bottom: 3%;
  padding: 2%;
  width: 100%;
  font-size: 14px;
  border-radius: 50px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
}

.info-fields {
  margin-top: 2%;
}

.info-item {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}

.info-item strong {
  font-weight: 900;
}

.info-item span {
  color: #ecf0f3;
}

@media (max-width: 768px) {
  .form {
    padding: 5%;
  }
}
