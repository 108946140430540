.sidebar {
  width: 80px;
  height: 100%;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.sidebar-items {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 8px;
  margin-top: 12px;
  border-radius: 8px;
  cursor: pointer;
  color: ghostwhite;
  transition: background-color 0.3s ease-in-out;
}

.item:hover {
  background-color: #005f8f;
}

.item img {
  width: 24px;
  height: 24px;
  margin-bottom: 6px;
}

.item span {
  font-size: 11px;
}

@media (max-width: 1024px) {
  .sidebar {
      width: 60px;
  }

  .item {
      padding: 12px 6px;
  }

  .item img {
      width: 20px;
      height: 20px;
  }

  .item span {
      font-size: 10px;
  }
}

@media (max-width: 768px) {
  .sidebar {
      width: 50px;
  }

  .item {
      padding: 10px 4px;
  }

  .item img {
      width: 18px;
      height: 18px;
  }

  .item span {
      font-size: 9px;
  }
}

@media (max-width: 600px) {
  .sidebar {
      width: 40px;
      padding: 8px 0;
  }

  .item {
      padding: 8px 2px;
  }

  .item img {
      width: 16px;
      height: 16px;
  }

  .item span {
      font-size: 8px;
  }
}
